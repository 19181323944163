import React from 'react';
import AsyncSelect from 'react-select/async';
import {IconFA} from 'components/Icons';

const getStyles = (customStyles) => {
  return {
    control: (styles, {isDisabled}) => ({
      ...styles,
      borderRadius: 0,
      borderColor: '#ced4da',
      color: isDisabled && '#6c757d',
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
    }),
    option: (styles, {isDisabled}) => ({
      ...styles,
      borderRadius: 0,
      color: !isDisabled && '#000',
    }),
    input: (styles) => styles,
    placeholder: (styles) => styles,
    singleValue: (styles) => styles,
    menu: (styles) => ({
      ...styles,
      zIndex: 5,
    }),
    ...customStyles,
  };
};

const defaultProps = {
  components: {
    DropdownIndicator: () => {
      return <IconFA name="search" className="mr-2 text-secondary" />;
    },
    IndicatorSeparator: () => null,
  },
};

function SearchAsyncBase(props) {
  const {isDisabled, styles, ...rest} = props;

  return (
    <AsyncSelect isDisabled={isDisabled} styles={getStyles(styles)} {...rest} />
  );
}

SearchAsyncBase.defaultProps = defaultProps;

export default SearchAsyncBase;
